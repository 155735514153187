import 'font-awesome/css/font-awesome.min.css';

import Header from '../header_loggedin'
import img from './images/pfp.jpeg';

export default function student() {
  return (
    <>
    <Header />
      <div className="content">
        <div className="heading1-div">
          <h1>Student Profile</h1>
        </div>
        <div className="profile">
          <div className="profile-details">
            <div className="card-body">
              <div className="form">
                <img src={img} alt="Profile Picture" />
                <h1>User Name</h1>
                <p>Email: user@example.com</p>
                <p>Role: Student</p>
                <form action="editprofilepage.html">
                  <label
                  ><span> </span><input type="submit" value="Edit Profile"
                    /></label>
                </form>
              </div>
            </div>
          </div>

          <div className="heading2-div">
            <h2>Manage Roles</h2>
          </div>
          <div className="table-responsive-sm table-responsive-lg">
            <div className="large-12 columns">
              <table className="table">
                <thead>
                  <tr>
                    <th>Role</th>
                    <th></th>
                  </tr>
                </thead>
                <tfoot></tfoot>
                <tbody>
                  <tr>
                    <td>Role 1</td>
                    <td>
                      <form action="">
                        <button className="button">
                          <i className="fa fa-pencil-square-o"></i>
                        </button>
                        <button className="button">
                          <i className="fa fa-trash-o"></i>
                        </button>
                      </form>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="heading2-div">
            <h2>Courses & Exams</h2>
          </div>
          <div className="table-responsive-sm table-responsive-lg">
            <div className="large-12 columns">
              <table className="table">
                <thead>
                  <tr>
                    <th>Role</th>
                    <th></th>
                  </tr>
                </thead>
                <tfoot></tfoot>
                <tbody>
                  <tr>
                    <td>Course 1</td>
                    <td>
                      <form action="">
                        <button className="button">
                          <i className="fa fa-pencil-square-o"></i>
                        </button>
                        <button className="button">
                          <i className="fa fa-trash-o"></i>
                        </button>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <td>Course 2</td>
                    <td>
                      <form action="">
                        <button className="button">
                          <i className="fa fa-pencil-square-o"></i>
                        </button>
                        <button className="button">
                          <i className="fa fa-trash-o"></i>
                        </button>
                      </form>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="heading2-div">
            <h2>Reports & Analytics</h2>
          </div>
          <p>No data available.</p>

          <div className="heading2-div">
            <h2>Chat</h2>
          </div>
          <div className="card-body">
            <div className="form">
              <input type="text" placeholder="Enter Message..." />
              <label
              ><span> </span><input type="submit" value="Send Message"
                /></label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

