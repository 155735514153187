import { Link } from "react-router-dom";

import Header from '../header'
export default function signup() {
  return (
    <>
    <Header />
      <div className="content">
        <div className="card-body">
          <div className="form">
            <h2 className="heading2-div">Sign Up</h2>
            <form className="login-form">
              <input type="text" placeholder="Full Name" />
              <input type="text" placeholder="Username" />
              <input type="email" placeholder="Email" />
              <input type="password" placeholder="Password" />
              <label><span> </span><input type="submit" value="Register" /></label>
              <p className="message">
                Already registered? <Link to="/login">Login here</Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}



