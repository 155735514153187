import './style.css';
import Header from '../header'

export default function aboutus() {
  return (
    <>
    <Header />
      <div className="content">
        <div className="heading1-div">
          <h1>About Us</h1>
        </div>
        <p>
          Welcome to Learner's Path, your trusted partner in academic excellence. We
          are dedicated to providing innovative solutions that enhance education and
          empower institutions.
        </p>

        <h3>Our Mission</h3>
        <p>
          Our mission is to simplify data management for educational institutions
          and promote data-driven decision-making. We believe that well-managed data
          can drive improvements in teaching, learning, and institutional
          effectiveness.
        </p>

        <h3>Our Team</h3>
        <p>
          Our team consists of passionate educators, data analysts, and software
          developers who are committed to the success of educational institutions.
          We bring together a wealth of experience in both education and technology
          to create cutting-edge solutions.
        </p>

        <h3>Why Choose Us?</h3>
        <p>
          We understand the unique challenges faced by educational institutions, and
          our solutions are tailored to meet those challenges. When you choose
          Course Data Management, you benefit from:
        </p>
        <ul>
          <li>Expertise in educational data management.</li>
          <li>User-friendly and intuitive software.</li>
          <li>Continuous updates and improvements.</li>
          <li>Exceptional customer support.</li>
          <li>Competitive pricing options.</li>
        </ul>

        <h3>Contact Us</h3>
        <p>
          If you have any questions or would like to learn more about our services,
          please don't hesitate to <a href="contactus.html">contact us</a>. We're
          here to help you achieve your educational goals.
        </p>
      </div>
    </>
  )
}

