import '../pages/style.css'
import Header from '../header'

export default function Home() {
    return (
        <>
        <Header />
            <div className="content">
                <div className="heading1-div">
                    <h1>Welcome to Our Academic Program</h1>
                </div>
                <p>
                    Our academic program is designed to provide students with a comprehensive
                    education that equips them with the knowledge and skills needed for
                    success.
                </p>
                <div className="heading2-div">
                    <h2>Program Objectives</h2>
                </div>
                <p>We have the following key objectives for our academic program:</p>
                <ul>
                    <li>Provide a strong foundation in relevant subjects.</li>
                    <li>Prepare students for real-world challenges.</li>
                    <li>Foster critical thinking and problem-solving abilities.</li>
                </ul>
                <div className="heading1-div">
                    <h2>Importance of Performance Measurement and Assessment</h2>
                </div>
                <p>
                    Performance measurement and assessment are crucial aspects of our program.
                    They help us:
                </p>
                <ul>
                    <li>Evaluate students' progress and learning outcomes.</li>
                    <li>Identify areas for improvement.</li>
                    <li>Ensure the quality of our education.</li>
                </ul>
            </div>
        </>
    )
}