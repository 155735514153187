import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

export default function Header() {
    return (
        <div className="header">
            <Link to="/" className="logo">Learner's Path</Link>
            <div className="header-right">
                <Link to="/">Home</Link>
                <Link to="/AboutUs">About Us</Link>
                <Link to="/Services">Services</Link>
                <Link to="/contactus">Contact Us</Link>
                <Link to="/login">Logout</Link>
                <Link to="https://mxa8363.uta.cloud/blog/">Blog</Link>
            </div>
        </div>
    )
}