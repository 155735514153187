import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


import App from './App';

import AboutUs from './pages/aboutus.js';

import Footer from './footer.js';
import Header from './header.js';
import Home from './pages/home.js';
import Services from './pages/services.js';
import ContactUs from './pages/contactus.js';
import Login from './pages/login.js';
import SignUp from './pages/signup.js';
import RecoverPassword from './pages/recoverpassword.js';
import Student from './pages/student.js';
import Administrator from './pages/administrator.js';
import EditProfile from './pages/editprofilepage.js';
import Instructor from './pages/instructor.js';
import ProgramCoordinator from './pages/program_coordinator.js';
import QAO from './pages/qao.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter> 
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact index element={<App />} />
      
        <Route exact path="/aboutus" element={<AboutUs />} />
        <Route exact path="/services" element={<Services />} />    
        <Route exact path="/contactus" element={<ContactUs />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/recoverpassword" element={<RecoverPassword />} />
        <Route exact path="/student" element={<Student />} />
        <Route exact path="/administrator" element={<Administrator />} />
        <Route exact path="/editprofilepage" element={<EditProfile />} />
        <Route exact path="/instructor" element={<Instructor />} />
        <Route exact path="/program_coordinator" element={<ProgramCoordinator />} />
        <Route exact path="/qao" element={<QAO />} />
      </Routes>
      <Footer />
    </BrowserRouter>


  </React.StrictMode>
);
