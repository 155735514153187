
import Header from '../header'
export default function ContactUs() {
  return (
    <>
    <Header/>
      <div className="content">
        <h1>Contact Us</h1>
        <p>
          Have a question or need more information? Feel free to get in touch with
          us using the contact form below:
        </p>
        <div className="card-body">
          <div className="form">

            <div className="form-floating">
              <input type="text" className="form-control" id="name" placeholder="Name"></input>
              <label for="name">Name</label>
            </div>

            <div className="form-floating">
              <input type="email" className="form-control" id="email" placeholder="name@example.com"></input>
              <label for="email">Email address</label>
            </div>

            <div className="form-floating">
              <textarea className="form-control" placeholder="Leave a comment here" id="comments" style={{ height: "100px" }}></textarea>
              <label for="comments">Comments</label>
            </div>

            <button className="btn btn-primary" style={{margin: "10px"}}>Submit</button>
          </div>
        </div>
        <div id="confirmation-message"></div>
      </div>
    </>
  )
}

