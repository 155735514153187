import img from './images/pfp.jpeg'
import Header from '../header_loggedin'

export default function editprofilepage() {
  return <>
  <Header />
    <div className="content">
    <div className="profile-details">
      <div className="card-body">
        <div className="form">
          <img src={img} alt="Profile Picture" />
          <h1>User Name</h1>
          <p>Email: user@example.com</p>
          <p>Role: TBD</p>
        </div>
      </div>
    </div>

    <div className="card-body">
      <div className="form">
        <h2>Update Information</h2>
        <form className="login-form">
          <input type="text" placeholder="Username" />
          <input type="email" placeholder="Email" />
          <input type="password" placeholder="Password" />
          <label><span> </span><input type="submit" value="Submit" /></label>
        </form>
      </div>
    </div>
  </div>
  </>
}
