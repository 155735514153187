import 'font-awesome/css/font-awesome.min.css';

import Header from '../header_loggedin'
import img from './images/pfp.jpeg';

export default function administrator() {
  return (
    <>
    <Header/>
      <div className="content">
        <div className="heading1-div">
          <h1>Administrator Profile</h1>
        </div>
        <div className="profile">
          <div className="profile-details">
            <div className="card-body">
              <div className="form">
                <img src={img} alt="Profile Picture" />
                <h1>User Name</h1>
                <p>Email: user@example.com</p>
                <p>Role: Administrator</p>
                <form action="/editprofilepage">
                  <label
                  ><span> </span><input type="submit" value="Edit Profile"
                    /></label>
                </form>
              </div>
            </div>
          </div>

          <div className="heading2-div">
            <h2>Manage User Permissions</h2>
          </div>
          <div className="table-responsive-sm table-responsive-lg">
            <div className="col-lg-12 columns">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>User Name</th>
                    <th>Read Permission</th>
                    <th>Write Permission</th>
                    <th>Delete Permission</th>
                    <th>Create Permission</th>
                  </tr>
                </thead>
                <tfoot></tfoot>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Admin</td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="heading2-div">
            <h2>Manage Students</h2>
          </div>
          <button>Add New Student</button>
          <br /><br />
          <div className="table-responsive-sm table-responsive-lg">
            <div className="large-12 columns">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Student Name</th>
                    <th>Delete</th>
                    <th>Edit</th>
                    <th>More Info</th>
                  </tr>
                </thead>
                <tfoot></tfoot>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Alice</td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                    <td><button>Information</button></td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>James</td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                    <td><button>Information</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="heading2-div">
            <h2>Manage Instructors</h2>
          </div>
          <button>Add New Instructor</button>
          <br /><br />
          <div className="table-responsive-sm table-responsive-lg">
            <div className="large-12 columns">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Instructor Name</th>
                    <th>Delete</th>
                    <th>Edit</th>
                    <th>More Info</th>
                  </tr>
                </thead>
                <tfoot></tfoot>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Instructor1</td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>

                    <td><button>Information</button></td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Instructor2</td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>

                    <td><button>Information</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="heading2-div">
            <h2>Manage QAO</h2>
          </div>
          <button>Add New QAO</button>
          <br /><br />
          <div className="table-responsive-sm table-responsive-lg">
            <div className="large-12 columns">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>QAO Name</th>
                    <th>Delete</th>
                    <th>Edit</th>
                    <th>More Info</th>
                  </tr>
                </thead>
                <tfoot></tfoot>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>QAO1</td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>

                    <td><button>Information</button></td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>QAO2</td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>

                    <td><button>Information</button></td>
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td>QAO3</td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>

                    <td><button>Information</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="heading2-div">
            <h2>Manage Program Co-ordinator</h2>
          </div>
          <button>Add New Program Co-ordinator</button>
          <br /><br />
          <div className="table-responsive-sm table-responsive-lg">
            <div className="large-12 columns">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Program Co-ordinator Name</th>
                    <th>Delete</th>
                    <th>Edit</th>
                    <th>More Info</th>
                  </tr>
                </thead>
                <tfoot></tfoot>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Program Co-ordinator1</td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>

                    <td><button>Information</button></td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Program Co-ordinator2</td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>

                    <td><button>Information</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="heading2-div">
            <h2>User Activity</h2>
          </div>
          <div className="table-responsive-sm table-responsive-lg">
            <div className="large-12 columns">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>User Name</th>
                    <th>Troubleshoot</th>
                  </tr>
                </thead>
                <tfoot></tfoot>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Alice</td>
                    <td>
                      View Activity
                      <form action="">
                        <button className="button">
                          <i className="fa fa-pencil-square-o"></i>
                        </button>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>James</td>
                    <td>
                      View Activity
                      <form action="">
                        <button className="button">
                          <i className="fa fa-pencil-square-o"></i>
                        </button>
                      </form>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="heading2-div">
            <h2>Chat</h2>
          </div>
          <div className="card-body">
            <div className="form">
              <input type="text" placeholder="Enter Recipient's ID..." />
              <input type="text" placeholder="Enter Message..." />
              <label
              ><span> </span><input type="submit" value="Send Message"
                /></label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


