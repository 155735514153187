import img from './images/pfp.jpeg';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';

import Header from '../header_loggedin'

export default function qao() {
  return (
    <>
    <Header />
    <div className="content">
      <div className="heading1-div">
        <h1>Quality Assurance Officer Profile</h1>
      </div>
      <div className="profile">
        <div className="profile-details">
          <div className="card-body">
            <div className="form">
              <img src={img} alt="Profile Picture" />
              <h1>User Name</h1>
              <p>Email: user@example.com</p>
              <p>Role: Quality Assurance Officer</p>
              <form action="/editprofilepage">
                <label
                  ><span> </span><input type="submit" value="Edit Profile"
                /></label>
              </form>
            </div>
          </div>
        </div>
  
        <div className="heading2-div">
          <h2>QA Processes and Policies</h2>
        </div>
        <div className="table-responsive-sm table-responsive-lg">
          <div className="">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>Policy</th>
                  <th>Type</th>
                  <th>Desc</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tfoot></tfoot>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>Fast Work</td>
                  <td>Process</td>
                  <td>Makes the work faster</td>
                  <td>
                    <button className="button">
                      <i className="fa fa-pencil-square-o"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <div className="heading2-div">
          <h2>Manage Recommendations</h2>
        </div>
        <button>Add New Recommendation</button>
        <br /><br />
        <div className="table-responsive-sm table-responsive-lg">
          <div className="large-12 columns">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>Recommendation Name</th>
                  <th>Delete</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tfoot></tfoot>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>Recom1</td>
                  <td>
                    <button className="button">
                      <i className="fa fa-pencil-square-o"></i>
                    </button>
                  </td>
                  <td>
                    <button className="button">
                      <i className="fa fa-pencil-square-o"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td>Recom2</td>
                  <td>
                    <button className="button">
                      <i className="fa fa-pencil-square-o"></i>
                    </button>
                  </td>
                  <td>
                    <button className="button">
                      <i className="fa fa-pencil-square-o"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <div className="heading2-div">
          <h2>Reports</h2>
        </div>
        <div className="table-responsive-sm table-responsive-lg">
          <div className="large-12 columns">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>Report Name</th>
                  <th>View</th>
                </tr>
              </thead>
              <tfoot></tfoot>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>Report1</td>
                  <td>
                    <button className="button">
                      <i className="fa fa-pencil-square-o"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td>Report2</td>
                  <td>
                    <button className="button">
                      <i className="fa fa-pencil-square-o"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <div className="heading2-div">
          <h2>Chat</h2>
        </div>
        <div className="card-body">
          <div className="form">
            <input type="text" placeholder="Enter Recipient's ID..." />
            <input type="text" placeholder="Enter Message..." />
            <label
              ><span> </span><input type="submit" value="Send Message"
            /></label>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}