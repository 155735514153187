import 'bootstrap/dist/css/bootstrap.css';

export default function footer() {
    return (
        <footer>
        <div className="footer-content">
          <h3>Learner's Path</h3>
          <p>This website makes the Data Management much more easier.</p>
        </div>
        <div className="footer-bottom">
          <p>copyright &copy; <a href="#">Learner's Path</a></p>
        </div>
      </footer>
    )
}