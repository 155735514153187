import Header from '../header'
export default function recoverpassword() {
  return ( 
    <>
    <Header />
      <div className="content">
    <div className="card-body">
      <div className="form">
        <form className="login-form">
          <input type="text" placeholder="Enter your Email" />
          <label
            ><span> </span><input type="submit" value="Recover Password"
          /></label>
        </form>
      </div>
    </div>
  </div>
    </>
  )
}
