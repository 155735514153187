import './pages/style.css';
import Home from './pages/home.js';


function App() {
  
  return (
    <>
    
      <Home />
    </>
  );
}

export default App;
