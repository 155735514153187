import Header from '../header';

export default function Services() {
  return (
    <>
    <Header/>
      <div className="content">
        <div className="heading1-div">
          <h1>Our Services</h1>
        </div>
        <ul>
          <li>
            <h3>Overview of the Academic Program and Objectives</h3>
            <p>
              We provide a detailed overview of our academic program and its key
              objectives to help you understand the educational journey.
            </p>
          </li>
          <li>
            <h3>Importance of Performance Measurement and Assessment</h3>
            <p>
              We highlight the significance of performance measurement and
              assessment in ensuring the quality of education.
            </p>
          </li>
          <li>
            <h3>Creation of Exams</h3>
            <p>
              We offer tools to create and manage exams for students, making the
              assessment process efficient and effective.
            </p>
          </li>
          <li>
            <h3>Tracking and Recording Student Performance</h3>
            <p>
              We provide features to track and record students' academic
              performance, helping educators make informed decisions.
            </p>
          </li>
          <li>
            <h3>Report Generation and Visualizations</h3>
            <p>
              Our system generates comprehensive reports and visualizations to
              present data in a meaningful way for better insights.
            </p>
          </li>
          <li>
            <h3>Statistical Insights</h3>
            <p>
              We offer statistical analysis tools to provide valuable insights into
              educational trends and patterns.
            </p>
          </li>
          <li>
            <h3>And Much More...</h3>
            <p>
              Explore our wide range of services designed to meet your data
              management and educational needs.
            </p>
          </li>
        </ul>
      </div>
    </>
  )
}
