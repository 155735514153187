import { Link } from "react-router-dom";
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

import Header from '../header'

export default function Login() {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const checkUserRoleAndRedirect = (username) => {

    console.log(username)
    switch (username.toLowerCase()) {
      case 'instructor':
        return '/instructor';
      case 'administrator':
        return '/administrator';
      case 'qao':
        return 'qao';
      case 'program-coordinator':
        return '/program_coordinator';
      case 'student':
        return "/student";
      default:
        return '/invalid-role';
    }
  }

    const handleLogin = () => {
      if (!username || !password) {
        setError('Please enter both username and password.');
        return;
      }

      if(password != 'admin') {
        setError('Incorrect password.');
        return;
      }
      const redirectPath = checkUserRoleAndRedirect(username);
      window.location.href = redirectPath
    }

    return (
      <>
<Header />
        <div className="content">
          <div className="card-body">
            <div className="form">
              <div className="login-form">
                <h2 className="heading2-div">Login</h2>
                <input type="text" placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)} />
                <input  placeholder="Password" 
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)} />
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <button className="btn btn-primary" onClick={handleLogin}>Login</button>
              
                <p className="message">
                  Forgot Password?  
                  <Link to="/recoverpassword">Recover Password here</Link>
                </p>
                <p className="message">
                  Not registered? <Link to="/signup">Create an account</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

