import 'font-awesome/css/font-awesome.min.css';

import Header from '../header_loggedin'
import img from './images/pfp.jpeg';

export default function program_coordinator() {
  return (
    <>
    <Header />
      <div className="content">
        <div className="heading1-div">
          <h1>Program Coordinator Profile</h1>
        </div>
        <div className="profile">
          <div className="profile-details">
            <div className="card-body">
              <div className="form">
                <img src={img} alt="Profile Picture" />
                <h1>User Name</h1>
                <p>Email: user@example.com</p>
                <p>Role: Program Co-ordinator Officer</p>
                <p>Program Name: Web Programming</p>
                <form action="/editprofilepage">
                  <label><span> </span><input type="submit" value="Edit Profile" /></label>
                </form>
              </div>
            </div>
          </div>

          <div className="heading2-div">
            <h2>Stakeholders List</h2>
          </div>
          <div className="table-responsive-sm table-responsive-lg">
            <div className="large-12 columns">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Name</th>
                    <th>Designation</th>
                    <th>Contact</th>
                  </tr>
                </thead>
                <tfoot></tfoot>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Norman</td>
                    <td>Admin</td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o">Click Me</i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Jenny</td>
                    <td>QAO</td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o">Click Me</i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="heading2-div">
            <h2>Performance Data</h2>
          </div>

          <div className="table-responsive-sm table-responsive-lg">
            <div className="large-12 columns">No Data Available</div>
          </div>

          <div className="heading2-div">
            <h2>Student Inquiries</h2>
          </div>
          <div className="row">
            <div className="large-12 columns">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Student Name</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tfoot></tfoot>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Inquiry Subject 1</td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Inquiry Subject 2</td>
                    <td>
                      <button className="button">
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="heading2-div">
            <h2>Chat</h2>
          </div>
          <div className="card-body">
            <div className="form">
              <input type="text" placeholder="Enter Recipient's ID..." />
              <input type="text" placeholder="Enter Message..." />
              <label><span> </span><input type="submit" value="Send Message" /></label>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}